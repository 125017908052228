import { Component, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-home-nine',
  templateUrl: './home-nine.component.html',
  styleUrl: './home-nine.component.css'
})
export class HomeNineComponent {
  // OWl Slider
  customOptions: OwlOptions = {
    loop: true,
    dots: true,
    margin:0,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
  }

  slideStore = [
    {
      // introtext: "One Page Parallax Template",
      title: "Your IT Innovation Partner",
      image: "assets/images/slides/home-bg-13.jpg",
      btnSlide: "btn btn-lg btn-gradient-primary btn-square mt-30",
      btnText: "Start a Project",
    },

    {
      // introtext: "An Award Winning",
      title: "Transforming Ideas into Reality.",
      image: "assets/images/slides/home-bg-14.jpg",
      btnSlide: "btn btn-lg btn-light-outline btn-square mt-30",
      btnText: "Purchase Now",
    },

    {
      // introtext: "Digital Solutions Agency",
      title: "Scaling Your Business with Tech.",
      image: "assets/images/slides/home-bg-15.jpg",
      btnSlide: "btn btn-lg btn-light btn-square mt-30",
      btnText: "Purchase Now",
    }

  ]

  // Type it
  phrases: string[] = ['DevOps', 'Cloud Services', 'Digital Markting','FrontEnd Development','BackEnd Development','UX/UI Designing','SEO','BlockChain Development','QA Engineering']; 
  displayedText: string = ''; 
  currentPhraseIndex: number = 0; 
  currentIndex: number = 0; 
  typingSpeed: number = 50; 
  delayBetweenPhrases: number = 1000;

  ngOnInit(): void {
    this.startTyping();
  }

  startTyping(): void {
    this.typePhrase();
  }

  typePhrase(): void {
    const phrase = this.phrases[this.currentPhraseIndex];

    const typingInterval = setInterval(() => {
      if (this.currentIndex < phrase.length) {
        this.displayedText += phrase.charAt(this.currentIndex);
        this.currentIndex++;
      } else {
        clearInterval(typingInterval);
        setTimeout(() => {
          this.startDeleting();
        }, this.delayBetweenPhrases); // Delay before starting to delete
      }
    }, this.typingSpeed);
  }

  startDeleting(): void {
    const deletingInterval = setInterval(() => {
      if (this.currentIndex > 0) {
        this.displayedText = this.displayedText.slice(0, -1);
        this.currentIndex--;
      } else {
        clearInterval(deletingInterval);
        this.currentPhraseIndex = (this.currentPhraseIndex + 1) % this.phrases.length;
        this.currentIndex = 0;
        setTimeout(() => {
          this.typePhrase();
        }, this.delayBetweenPhrases); // Delay before starting to type the next phrase
      }
    }, this.typingSpeed / 2); // Faster deleting speed
  }

  // Our Process
  steps = [
    {
      icon:"icon-tools default-color font-30px",
      title:"Design",
      desc:"We work closely with you to create visually appealing and user-friendly designs that align with your vision and goals. Our focus is on delivering an exceptional user experience that sets the foundation for success."
    },
    {
      icon:"icon-globe default-color font-30px",
      title:"Development",
      desc:"Our team transforms designs into functional, high-performance solutions using the latest technologies. We ensure every feature is tailored to meet your business needs, emphasizing scalability and security."
    },
    {
      icon:"icon-mobile default-color font-30px",
      title:"Testing",
      desc:"To guarantee quality, we conduct thorough testing, covering functionality, usability, performance, and security. Every detail is refined to ensure the final product is flawless and ready for launch."
    },
    {
      icon:"icon-browser default-color font-30px",
      title:"Live",
      desc:"We seamlessly deploy your project, ensuring everything runs smoothly. Post-launch, our team provides ongoing support and maintenance to keep your solution performing at its best."
    },
  ]

  // Owl Slider Team
  customOptionsTwo: OwlOptions = {
    loop: true,
    dots: true,
    margin: 30,
    nav: false,
    autoplay: true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 3,
    navText: ['', ''],
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:6,
      }
    }
  };

  slideStoreTwo: { title: string; subtitle: string; image: string; index: number }[] = [
    {
      title: "Founder | CEO | CTO",
      subtitle: "Arsalan Ahmed",
      image: "team-1.jpg",
      index: 0
    },
    {
      title: "Lead Cloud Engineer",
      subtitle: "M Kamran",
      image: "team-2.jpg",
      index: 1
    },
    {
      title: "UX/UI Desinger",
      subtitle: "Shaheryar Anwer",
      image: "team-3.jpg",
      index: 2
    },
    {
      title: "Sr. DevOps Engineer",
      subtitle: "Usama Shahid",
      image: "team-4.jpg",
      index: 3
    }
  ];

  socialLinks = [
    { iconOuter: "facebook", iconInner: ['icofont icofont-social-facebook'] },
    { iconOuter: "twitter", iconInner: ['icofont icofont-social-twitter'] },
    // { iconOuter: "pinterest", iconInner: ['icofont icofont-social-pinterest'] },
  ];

  // Services
  services = [
    {
      icon: "icon-genius font-30px white-color",
      title: "AI / Data Sciences",
      desc: "Leverage advanced AI and data science solutions to unlock actionable insights, enhance decision-making, and drive business innovation.",
    },
    {
      icon: "icon-attachment font-30px white-color",
      title: "Blockchain Development",
      desc: "Cloudchain Technologies provides blockchain solutions, including custom apps, smart contracts, DApps, and system integration, ensuring secure and efficient innovation.",
    },
    {
      icon: "icon-tools-2 font-30px white-color",
      title: "Cloud Infrastructure",
      desc: "Cloudchain Technologies provides cloud infrastructure services, offering scalable, secure, and optimized solutions to support efficient business operations and growth.",
    },
    {
      icon: "icon-upload font-30px white-color",
      title: "Cloud Migration",
      desc: "We offer efficient and secure cloud migration services, ensuring seamless transitions of applications, data, and systems to the cloud with minimal downtime and optimal performance.",
    },
    {
      icon: "icon-gears font-30px white-color",
      title: "DevOps / Automation",
      desc: "We streamline development and operations with efficient CI/CD pipelines, automation, and collaboration, ensuring faster delivery and improved reliability.",
    },
    {
      icon: "icon-presentation font-30px white-color",
      title: "Digital Marketing",
      desc: "We boost your digital presence through strategic SEO, social media, and marketing solutions designed to drive engagement and conversions.",
    },
    {
      icon: "icon-cloud font-30px white-color",
      title: "Managed Cloud Services",
      desc: "We provide managed cloud services, ensuring reliable monitoring, maintenance, and optimization of cloud infrastructure to enhance performance, security, and scalability for your business.",
    },
    {
      icon: "icon-tools font-30px white-color",
      title: "UX/UI Designing",
      desc: "We provide UX/UI design services to create user-friendly, visually appealing, and responsive interfaces, ensuring seamless experiences and enhanced engagement.",
    },
    {
      icon: "icon-browser font-30px white-color",
      title: "Web Development",
      desc: "We offer comprehensive web development services, including front-end design, back-end logic, and full-stack solutions. Our expertise ensures secure, functional, and user-friendly websites and web applications tailored to your needs.",
    }
  ]

  // Portfolio
  portfolios = [
    {
      img: "1.jpg",
      title: "Fode ",
      subTitle: "Branding, Mockup"
    },
    {
      img: "2.jpg",
      title: "Daniel and Denise",
      subTitle: "Branding, UI/UX"
    },
    {
      img: "3.jpg",
      title: "Sydney Design",
      subTitle: "Design, Stationery"
    },
    {
      img: "4.jpg",
      title: "Glasses",
      subTitle: "Branding, UI/UX"
    },
    {
      img: "5.jpg",
      title: "The Bijou Factory",
      subTitle: "Branding, Mockup"
    },
    {
      img: "6.jpg",
      title: "Calender",
      subTitle: "Design, Branding"
    },
    {
      img: "7.jpg",
      title: "Flyer",
      subTitle: "Branding, Stationery"
    },
    {
      img: "8.jpg",
      title: "Hand Watch",
      subTitle: "Design, UI/UX"
    }
  ]

  // Pricing
  pricing = [
    {
      title: "Basic Plan",
      subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "$",
      amount: 9.99,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      btnText: "Get Started",
      features: [
        'Mobile-Optimized Website',
        'Powerful Website Metrics',
        'Free Custom Domain',
        '24/7 Customer Support',
        'Fully Integrated E-Commerce',
        'Sell Unlimited Products &amp; Accept Donations',
        'No CMS items',
        'No site search',
        'No CMS API access',
        'No content editors'
      ]
    },

    {
      title: "Standard Plan",
      subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "$",
      amount: 16.99,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      btnText: "Get Started",
      features: [
        'Mobile-Optimized Website',
        'Powerful Website Metrics',
        'Free Custom Domain',
        '24/7 Customer Support',
        'Fully Integrated E-Commerce',
        'Sell Unlimited Products &amp; Accept Donations',
        'No CMS items',
        'No site search',
        'No CMS API access',
        'No content editors'
      ]
    },

    {
      title: "Extended Plan",
      subTitle: "An affordable option for end-to-end hiring at small companies.",
      currency: "$",
      amount: 24.99,
      btnClass: "btn btn-dark btn-md btn-default btn-square",
      btnText: "Get Started",
      features: [
        'Mobile-Optimized Website',
        'Powerful Website Metrics',
        'Free Custom Domain',
        '24/7 Customer Support',
        'Fully Integrated E-Commerce',
        'Sell Unlimited Products &amp; Accept Donations',
        'No CMS items',
        'No site search',
        'No CMS API access',
        'No content editors'
      ]
    }
  ];

  // Owl Testimonial
  customOptionsThree: OwlOptions = {
    loop: true,
    dots: true,
    margin:30,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 3,
    navText: ['<i class="icofont icofont-arrow-left"></i>', '<i class="icofont icofont-arrow-right"></i>'],
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:3,
      }
    }
  }

  slideStoreThree = [
    {
      title: "Haytham Allos",
      subTitle: "RealToken Technologies",
      description: "The Cloudchain Technologies team is amazing at DevOps and pays strong attention to detail. Love having them as part of my team.",
      image: "assets/images/team/avatar-1.jpg",
    },

    {
      title: "Joe Gilharry",
      subTitle: "CEO, AnalyzeREI",
      description: "Great DevOps team, helped out a lot over the years on our projects. Highly recommend them!",
      image: "assets/images/team/avatar-2.jpg",
    },

    {
      title: "Mookane Munawwar",
      subTitle: "CEO, Azure Labs",
      description: "The Cloudchain Technologies team was really great to work with. They are effective communicators who also ensure quality delivery on time. Definitely recommended!!!",
      image: "assets/images/team/avatar-3.jpg",
    },

    {
      title: "Ruben Masso",
      subTitle: "CEO, Masso Labs",
      description: "Great team, they helped us with emergency situations and were very knowledgeable.",
      image: "assets/images/team/avatar-4.jpg",
    }

  ]

  // Owl Brand Logos
  customOptionsFour: OwlOptions = {
    loop: true,
    dots: false,
    margin:0,
    nav:false,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 6,
    responsive:{
      0:{
          items:1,
      },
      600:{
          items:2,
      },
      1000:{
          items:6,
      }
    }
  }

  slideStoreFour = [
    {
      image: "assets/images/clients/1.png",
    },

    {
      image: "assets/images/clients/2.png",
    },

    {
      image: "assets/images/clients/3.png",
    },

    {
      image: "assets/images/clients/4.png",
    },

    {
      image: "assets/images/clients/5.png",
    },

    {
      image: "assets/images/clients/6.png",
    }

  ]



  // Contact Form
  formData = {
    user_name: '',
    user_email: '',
    message: ''
  };

  // EmailJS service configuration
  private serviceId = 'service_kg6djeq';  // Replace with your EmailJS Service ID
  private templateId = 'template_n54pd8k';  // Replace with your EmailJS Template ID
  private publicKey = 'eRyspz-YRT36FQIBK';  // Replace with your EmailJS Public Key

  // Method to send email on form submission
  public sendEmail(event: Event): void {
    event.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm(this.serviceId, this.templateId, event.target as HTMLFormElement, this.publicKey)
      .then(
        (response: EmailJSResponseStatus) => {
          alert('Message sent successfully. Thank you, we will get back to you soon!');
          this.resetForm();
        },
        (error: EmailJSResponseStatus) => {
          console.error('Failed to send message. Error:', error.text);
          alert('Failed to send the message. Please try again later.');
        }
      );
  }

  // Method to reset the form after submission
  private resetForm(): void {
    this.formData = {
      user_name: '',
      user_email: '',
      message: ''
    };
  }

  socialMedia = [
    {
      iconClass: "fb",
      iconLink: "https://www.facebook.com/cloudchaintechnologies",
      icon:"icofont icofont-social-facebook"
    },
    // {
    //   iconClass: "tw",
    //   iconLink: "#",
    //   icon:"icofont icofont-social-twitter"
    // },
    // {
    //   iconClass: "be",
    //   icon:"icofont icofont-social-behance"
    // },
    {
      iconClass: "in",
      iconLink: "https://www.linkedin.com/company/cloudchaintechnologies",
      icon:"icofont icofont-social-linkedin"
    },
    // {
    //   iconClass: "yt",
    //   icon:"icofont icofont-social-youtube"
    // },
    // {
    //   iconClass: "ins",
    //   icon:"icofont icofont-social-instagram"
    // }
  ]

  // Skills
  skills = [
    { name: 'DevOps', level: 95 },
    { name: 'Cloud Services', level: 95 },
    { name: 'UI/UX Designing', level: 90 },
    { name: 'Development', level: 85 },
    { name: 'Markting', level: 85 },
    { name: 'BlockChain Devolpment', level: 80 },
    { name: 'Solution Architect', level: 80 }
  ];

  isScrolled = false;

  @HostListener('window:scroll', [])
  onScroll(): void {
    const skillsSection = document.querySelector('.skill');
    
    // Add a null check for skillsSection
    if (skillsSection) {
      const position = skillsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Check if the section is in view
      if (position < windowHeight) {
        this.isScrolled = true;
      }
    }
  }

  // Video Popup
	isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }
}
